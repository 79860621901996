<!-- fullfillment 海外仓备货上架批次 -->
<template>
	<div 
	:lang="$i18n.locale"
	v-loading.fullscreen="uploadloading"
	:element-loading-text="$t('tipsInfo.uploading')"
	element-loading-spinner="el-icon-loading"
	element-loading-background="rgba(0, 0, 0, 0.8)" 
	class="app-container">
		<div class="listHeader">
			<i></i><span>{{$t('ksaWhBatchListPage.pageTitle')}}</span>
		</div>
		<el-form style="margin:10px 0 0 0" :model="queryParams" ref="queryParams" :inline="true" label-width="auto" @submit.native.prevent>
			<el-form-item label prop="orderNo">
				<el-input v-model.trim="queryParams.orderNo" clearable
					:placeholder="$t('commonInfo.orderNoColumn')" style="width: 200px;"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button icon="el-icon-search" type="primary" @click="handleSearch">
					{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button icon="el-icon-refresh" type="primary" @click="getlist">
					{{$t('commonInfo.refreshBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}
				</el-button>
				<el-button icon="el-icon-plus" type="primary" @click="addOrder">{{$t('commonInfo.createOrder')}}</el-button>
			</el-form-item>
		</el-form>
		<el-table
		  :data="tableData"
		  border
			ref="b2bOrderTable"
		  style="width: 100%;font-size: 16px;"
			v-loading="loading"
			:row-key="rowKey"
			:expand-row-keys="expandRowArray"
			:row-class-name="setClassName"
		>
		  <el-table-column type="expand" label="" align="center" width="1">
		    <template slot-scope="{row}">
		      <el-row class="expandDataRow" v-for="subOrder in row.subOrderList" :key="subOrder.id" type="flex" justify="center" align="middle">
		        <el-col :span='3'>
		            <img style="width: 100px;height: 100px; margin: 0px auto; display: block;" src="../../assets/images/subOrder.png" />
		        </el-col>
		        <el-col :span="21">
		          <el-descriptions :column="2" border :labelStyle="{'width':'160px','text-align':'right','font-weight':'bold'}" >
		            <el-descriptions-item :label="$t('commonInfo.batch')">{{subOrder.batchCount}}</el-descriptions-item>
		            <el-descriptions-item :label="$t('commonInfo.subOrderNo')">{{subOrder.esnadTobOrderNo}}</el-descriptions-item>
		            <el-descriptions-item :label="$t('commonInfo.packageAmount')">{{subOrder.packageAmount}}</el-descriptions-item>
		            <el-descriptions-item :label="$t('commonInfo.time')">{{subOrder.initTime}}</el-descriptions-item>
		            <el-descriptions-item :label="$t('commonInfo.statusColumn')">{{subOrder.memo}}</el-descriptions-item>
		          </el-descriptions>
		        </el-col>
		      </el-row>
		  	</template>
		  </el-table-column>
		  <el-table-column :label="$t('ksaWhBatchListPage.orderNoOrBatchNo')" min-width="160" prop="esnadTobOrderNo" align="center"></el-table-column>
			<el-table-column :label="$t('commonInfo.packageAmount')" min-width="100" prop="packageAmount" align="center">
			  <template slot-scope="scope">{{ scope.row.packageAmount }}</template>
			</el-table-column>
		  <el-table-column :label="$t('commonInfo.deliveryWarehouse')" min-width="160" prop="dest" align="center">
				<template slot-scope="scope">
				<!-- {{ scope.row.dest == "custom"?"自定义海外仓":scope.row.dest }} -->
				<span>{{$t('commonInfo.fleetanWarehouse')}}</span>
				</template>
			</el-table-column>
		  <el-table-column :label="$t('commonInfo.statusColumn')" min-width="140" align="center">
		    <template slot-scope="scope">
				  <div>
						<span style="display: block;">{{returnStatusName(scope.row)}}</span>
						<div v-if="isShowPod(scope.row)">
							<el-button type="text" icon="el-icon-picture" style="height: 22px; padding: 0px;" size="small" @click="checkPOD(scope.row.podLink)">{{$t('commonInfo.actionCheckPOD')}}</el-button>
						</div>
					</div>
				</template>
		  </el-table-column>
			<el-table-column :label="$t('commonInfo.orderType')" min-width="140" align="center">
			  <template slot-scope="scope">
				  <span v-if="scope.row.warehouseType == '4'">{{$t('commonInfo.skuStockType')}}</span>
					<span v-if="scope.row.warehouseType == '3'">{{$t('commonInfo.cartonStockType')}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.remark')" min-width="160" align="center" prop="remarks">
				<template slot-scope="{row}">
					<span
					  v-if="row.remarks == '' || row.remarks == null || row.remarks.length<36">{{row.remarks}}</span>
					<div v-else>
					  <p style="margin: 0;">{{row.remarks.substring(0,36) + '...'}}</p>
					  <el-button size="mini" type="text" @click="handleCheckRemark(row)">{{$t('commonInfo.viewMore')}}</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.initTime')" prop="initTime" align="center" min-width="180">
			  <template slot-scope="{ row }">
			    <div>{{ row.initTime | formatDate }}</div>
			  </template>
			</el-table-column>
		  <el-table-column :label="$t('commonInfo.operationColumn')" class-name="small-padding fixed-width" fixed="right" width="140" align="center" style="padding-right:0">
		    <template slot-scope="{ row }">
					<!-- <el-button type="text" size="small" v-if="isEditOrder(row)" @click="editOrder(row)" icon="el-icon-edit-outline"  style="margin-left:10px">{{$t('commonInfo.modifyOrder')}}</el-button> -->
					<el-button type="text" size="small" @click="handleCheckPackageInfo(row)" icon="el-icon-box" style="margin-left:10px">{{$t('chinaWhB2COrderPage.squareWeighing')}}</el-button>
					<!-- <el-button type="text" size="small" v-if="row.warehouseType == '3' && row.remarks == ''" @click="toSku(row)" icon="el-icon-sort">{{$t('ksaWhBatchListPage.turnToSkuStock')}}</el-button> -->
					<el-button type="text" size="small" @click="xiang(row.esnadTobOrderNo)" icon="el-icon-s-promotion">{{$t('commonInfo.actiontrack')}}</el-button>
					<el-button v-if="isShowPDF(row)" type="text" size="small" @click="PDFSheet(row.waybillLink)" icon="el-icon-download">
						{{$t('commonInfo.downloadBoxStickers')}}</el-button>
					<!-- <el-button v-if="row.warehouseType == '4'" type="text" size="small" @click="CheckSkuList(row)" icon="el-icon-s-goods">{{$t('commonInfo.actionCheckProduct')}}</el-button> -->
					<!-- <el-button v-if="row.warehouseType == '3'" type="text" size="small" @click="skuSheet(row)" icon="el-icon-download">{{$t('commonInfo.actionDownloadSkuList')}}</el-button> -->
					<!-- <el-badge v-if="row.subOrderList != null && row.subOrderList.length > 0" is-dot class="item batchInfoItem">
					  <el-button type="text" size="small" @click="handleExpandRow(row)" :icon="row.expand?'el-icon-arrow-up':'el-icon-arrow-down'">{{$t('chinaWhB2BOrderPage.batchTrackingTitle')}}</el-button>
					</el-badge> -->
					<el-dropdown style="margin-left: 10px;" @command="handleCommand">
						<span style="font-size: 12px; color: #409EFF;" class="el-dropdown-link">{{$t('commonInfo.moreAction')}}<i
						    class="el-icon-arrow-down el-icon--right"></i></span>
						<el-badge v-if="row.subOrderList != null && row.subOrderList.length > 0" is-dot class="item">
						</el-badge>
					  <el-dropdown-menu slot="dropdown">
					    <el-dropdown-item v-if="isEditOrder(row)" :command="{'command':'a','row':row}">{{ $t('commonInfo.modifyOrder') }}</el-dropdown-item>
							<el-dropdown-item v-if="row.warehouseType == '3' && row.remarks == ''" :command="{'command':'b','row':row}">{{ $t('ksaWhBatchListPage.turnToSkuStock') }}</el-dropdown-item>
							<el-dropdown-item v-if="row.warehouseType == '4'" :command="{'command':'c','row':row}">{{ $t('commonInfo.actionCheckProduct') }}</el-dropdown-item>
							<el-dropdown-item v-if="row.warehouseType == '3'" :command="{'command':'d','row':row}">{{ $t('commonInfo.actionDownloadSkuList') }}</el-dropdown-item>
							<el-badge v-if="row.subOrderList != null && row.subOrderList.length > 0" is-dot class="item batchInfoItem1">
							  <el-dropdown-item :command="{'command':'e','row':row}">{{ $t('chinaWhB2BOrderPage.batchTrackingTitle') }}</el-dropdown-item>
							</el-badge>
					  </el-dropdown-menu>
					</el-dropdown>
		    </template>
		  </el-table-column>
		</el-table>
		<el-image-viewer v-if="showImg" :on-close="closeImg" :url-list="imgList" />
		<div class="pageBottom">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryParams.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.limit"
				layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<!-- 查看订单箱信息 -->
		<el-dialog :lang="$i18n.locale" :title="$t('chinaWhB2BOrderPage.dialogPackageInfoTitle')" :visible.sync="packageDialogPopup" width="1200px" :close-on-click-modal="false">
			<el-row>
				<label style="padding: 20px;display: inline-block;font-size: 18px;">{{$t('commonInfo.orderNoColumn') + ':'}}</label><span style="padding: 20px;display: inline-block; font-size: 18px;">{{checkPackageOrderNo}}</span>
			</el-row>
			<el-row class="cartonCount">
			  <label>{{$t('chinaWhB2BOrderPage.inWhCount')}}: <span style="color: #01AAED;">{{cartonCountInfo.inWhCount}}</span></label>
			  <label>{{$t('chinaWhB2BOrderPage.cancelCount')}}: <span style="color: #dc191d;">{{cartonCountInfo.cancelCount}}</span></label>
			</el-row>
			<el-table :data="packageData" v-loading="packageListLoading" show-summary :summary-method="getSummaries">
				<el-table-column prop="clientLgPackageNo" :label="$t('commonInfo.packageNo')" width="160" align="center">
				</el-table-column>
				<el-table-column prop="esnadLgPackageNo" :label="$t('commonInfo.dlvdPackageNo')" width="180" align="center">
				</el-table-column>
				<el-table-column prop="subOrderNo" :label="$t('commonInfo.batchNo')" align="center" width="150"></el-table-column>
				<el-table-column prop="length" :label="$t('commonInfo.length') + '(CM)'" align="center"></el-table-column>
				<el-table-column prop="breadth" :label="$t('commonInfo.breadth') + '(CM)'" align="center"></el-table-column>
				<el-table-column prop="height" :label="$t('commonInfo.height') + '(CM)'" align="center"></el-table-column>
				<el-table-column prop="grossWeight" :label="$t('commonInfo.grossWeight') + '(KG)'" align="center"></el-table-column>
				<el-table-column prop="chargedWeight" :label="$t('commonInfo.chargeWeight') + '(KG)'" align="center" min-width="90"></el-table-column>
				<el-table-column prop="productAmount" :label="$t('commonInfo.productAmount')" align="center" min-width="90"></el-table-column>
				<el-table-column prop="picUrl" min-width="100" :label="$t('commonInfo.photo')" align="center">
					<template slot-scope="{row}">
						<div v-if="row.picUrl != null && row.picUrl != ''">
							<el-button type="text" @click="handleViewCartonImg(row.picUrl)" icon="el-icon-view">
								{{$t('rtoFbxOrderPage.checkPhoto')}}</el-button>
						</div>
						<span v-else></span>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
		<!-- 添加订单前选择类型和提示  关闭整箱上架width540 显示整箱上架width940-->
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.orderType')" :visible.sync="dialogOrderTypeVisible" :width="showCarton?'940px':'549px'" :close-on-click-modal="false">
		  <el-row :gutter="20">
				<el-col v-if="showCarton" :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>{{$t('commonInfo.fleetanWarehouse') + ' ' + $t('commonInfo.cartonStockType') }}</span>
						</div>
						<el-row style="height: 80px;"><span>{{$t('ksaWhBatchListPage.cartonStockDes')}}</span></el-row>
						<el-row style="text-align: center;margin: 10px 0;"><el-button type="primary" @click="ChooseCreateType('2')" style="margin-left:10px;">{{$t('commonInfo.createBtn')}}</el-button></el-row>
					</el-card>
				</el-col>
				<el-col :span="showCarton?12:24">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>{{$t('commonInfo.fleetanWarehouse') + ' ' + $t('commonInfo.skuStockType') }}</span>
						</div>
						<el-row style="height: 80px;"><span>{{$t('ksaWhBatchListPage.skuStockDes')}}</span></el-row>
						<el-row style="text-align: center;margin: 10px 0;"><el-button type="primary" @click="ChooseCreateType('1')" style="margin-left:10px;">{{$t('commonInfo.createBtn')}}</el-button></el-row>
					</el-card>
				</el-col>
			</el-row>
		</el-dialog>
		<!-- 添加订单窗口 -->
		<el-dialog :lang="$i18n.locale" :title="orderTitle" :visible.sync="orderPopup" width="840px" append-to-body
			:close-on-click-modal="false" v-loading="DialogLoading" @close="handleDialogClose">
			<el-form ref="orderForm" :model="form" :rules="rules" :inline="true" label-width="150px">
				<el-form-item v-show="false" :label="$t('commonInfo.deliveryCountry')" prop="destCountry">
					<el-select v-model="form.destCountry" :placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryCountry')" disabled style="width: 100%;">
						<el-option v-for="item in countryList" :key="item.value" :label="item.name" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-show="false" :label="$t('commonInfo.deliveryType')" prop="warehouseType">
					<el-select v-model="form.warehouseType" :placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryType')" disabled style="width: 100%;">
						<el-option v-for="item in warehouseTypeList" :key="item.value" :label="item.name"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-show="false" :label="$t('commonInfo.deliveryWarehouse')" prop="destWhNo">
					<el-select v-model="form.destWhNo" filterable  :placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryWarehouse')" disabled style="width: 100%;">
						<el-option v-for="item in selectWhList" :key="item.dictCode" :label="item.dictLabel"
							:value="item.dictValue"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-show="false" :label="$t('commonInfo.deliveryDest')" prop="otherDest">
					<el-input style="width: 220px" v-model="form.otherDest" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.deliveryDest')" disabled></el-input>
				</el-form-item>
				<!-- 选择自定义仓库时 需要必填联系人和联系电话 -->
				<el-form-item v-show="false" :label="$t('commonInfo.contactMan')" prop="contacts">
					<el-input v-model="form.contacts" style="width: 220px" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contactMan')" disabled></el-input>
				</el-form-item>
				<el-form-item v-show="false" :label="$t('commonInfo.contact')" prop="contactsTel">
					<el-input v-model="form.contactsTel" style="width: 220px" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contact')" disabled></el-input>
				</el-form-item>
				<el-form-item v-if="createType == '2'" :label="$t('commonInfo.deliveryType')" prop="dispatchType">
					<el-select v-model="form.dispatchType" :placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryType')" style="width: 575px;">
						<el-option v-for="item in dispatchTypeList" :key="item.value" :label="item.name"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<!-- 是否报关退税 -->
				<el-form-item :label="$t('chinaWhB2BOrderPage.customsDeclaration')" prop="customsDeclarationFlag" style="display: block;">
					<el-radio v-model="form.customsDeclarationFlag" :label="true">{{$t('chinaWhB2BOrderPage.officialCD')}}</el-radio>
					<el-radio v-model="form.customsDeclarationFlag" :label="false">{{$t('chinaWhB2BOrderPage.unofficialCD')}}</el-radio>
				</el-form-item>
				<!-- 客户订单号 必填 根据客户信息设置判断是否显示 -->
				<el-form-item v-if="isSpecial" :label="$t('chinaWhB2BOrderPage.clientOrderNo')" prop="clientOrderNo">
					<el-input v-model="form.clientOrderNo" style="width: 215px"
						:placeholder="$t('tipsInfo.inputPlaceholder') + $t('chinaWhB2BOrderPage.clientOrderNo')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.declaredValue') + '(USD)'" prop="declaredValue">
					<el-input-number v-model.number="form.declaredValue" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.declaredValue')">
					</el-input-number>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.orderType')" prop="clientOperationType">
					<el-radio style="margin-right: 40px;" v-model="form.clientOperationType" label="1">
						{{$t('chinaWhB2BOrderPage.express')}}</el-radio>
					<el-radio v-model="form.clientOperationType" label="2">Cargo</el-radio>
				</el-form-item>
				<div v-if="createType == '2'">
					<el-form-item :label="$t('commonInfo.skuCount')" prop="skuCount">
						<el-input-number v-model.number="form.skuCount" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.skuCount')">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="$t('chinaWhB2BOrderPage.kindnameDes')">
						<el-select v-model="kindName" value-key="id" multiple :placeholder="$t('chinaWhB2BOrderPage.kindnameSelectTips')"
							style="width: 575px;">
							<el-option v-for="item in kindNameList" :key="item.id" :label="item.label"
								:value="item.value">
								<span style="float: left">{{ item.productChineseDesc }}</span>
								<span
									style="float: right; margin-right: 20px; color: #8492a6; font-size: 13px">{{ item.productEnglishDesc }}</span>
							</el-option>
						</el-select>
						<!-- <span class="productTip">若该订单包含5种以上产品，建议填写至少5种主要产品。若该订单少于5种产品，则需一一填写。</span> -->
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="openKindName" style="margin-left: 10px;" icon="el-icon-plus">{{$t('chinaWhB2BOrderPage.dialogAddKindnameTitle')}}</el-button>
					</el-form-item>
				</div>
			</el-form>
			<el-card class="box-card" v-loading="caseLoading">
				<div slot="header" class="clearfix">
					<span>{{$t('chinaWhB2BOrderPage.clientOrderPackageAmount')}}</span>
					<div style="display: inline-block;" v-if="!isEdit">
						<el-input v-model.number="caseNum" style="width: 120px; margin-left: 20px;" type="tel"
							@input="handleNum()"></el-input>
						<span style="margin-left: 10px; color: #bdbdbd;">{{$t('chinaWhB2BOrderPage.createBoxNoTips')}}</span>
					</div>
				</div>
				<!-- 超过十条数据隐藏 -->
				<el-collapse v-show="caseNum > 0" v-model="showCaseDetail">
					<el-collapse-item :title="$t('chinaWhB2BOrderPage.generated') + caseNum + $t('chinaWhB2BOrderPage.boxes') + ',' + $t('chinaWhB2BOrderPage.expandAndHide')" name="1">
					  <div v-if="isCaseNormal">
					  	<el-row class="productRow" style="margin: 20px 0;" v-for="(item, index) in caseData" :key="index">
					  		<el-col style="width: 107px;margin-right: 12px;">
					  			<span
					  				style="display: inline-block;height: 40px; text-align: right;padding: 0 12px 0 0; line-height: 40px;width: 100%;font-size: 16px;">{{$t('commonInfo.packageNo')}}</span>
					  		</el-col>
					  		<el-col :span="10">
					  			<el-input v-model="item.input" style="font-size: 16px;" disabled />
					  		</el-col>
					  		<el-col :span="8" v-if="isEdit">
					  			<el-button v-show="index == (caseData.length - 1) && caseDataCustom.length == 0"
					  				size="medium" type="text" icon="el-icon-circle-plus"
					  				style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
					  				@click="addCaseItem(item)" />
					  			<el-button size="medium" type="text" icon="el-icon-delete"
					  				style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
					  				@click="delCaseItem(item,index)" />
					  		</el-col>
					  	</el-row>
					  </div>
					  <div v-if="isCaseSpecial">
					  	<el-row class="productRow" style="margin: 20px 0;" v-for="(item,index) in caseDataCustom" :key="index"
					  	:style="{padding: '10px 0',backgroundColor:item.color}">
					  		<el-col style="width: 107px;margin-right: 12px;">
					  			<span
					  				style="display: inline-block;height: 40px; text-align: right;padding: 0 12px 0 0; line-height: 40px;width: 100%;font-size: 16px;">{{$t('commonInfo.packageNo')}}</span>
					  		</el-col>
					  		<el-col :span="12">
					  			<span
					  				style="display: inline-block;height: 40px; text-align: right;padding: 0 12px 0 0; line-height: 40px;font-size: 16px;">{{item.preNum}}</span>
					  			<el-input v-model="item.input" maxlength="20" @input="handleInputCustomCase()" style="font-size: 16px;width: 200px;">
					  				<template slot="suffix">
					  					<el-tooltip v-if="item.input != ''" class="item" effect="dark" :content="$t('chinaWhB2BOrderPage.caseAutoFillTips')" placement="top-start">
					  						<i class="el-input__icon el-icon-document" @click="autoFillCaseInfo(item,index)"></i>
					  					</el-tooltip>
					  				</template>
					  			</el-input>
					  			<span
					  				style="display: inline-block;height: 40px; text-align: right;padding: 0 0 0 12px; line-height: 40px;font-size: 16px;">{{item.nextNum}}</span>
					  		</el-col>
					  		<el-col :span="6">
					  			<el-button v-show="index == (caseDataCustom.length - 1)" size="medium" type="text"
					  				icon="el-icon-circle-plus"
					  				style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
					  				@click="addCustomCaseItem(item)" />
					  			<el-button size="medium" type="text" icon="el-icon-delete"
					  				style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
					  				@click="delCaseCustomItem(item,index)" />
					  		</el-col>
					  	</el-row>
					  </div>
					</el-collapse-item>
				</el-collapse>
				
			</el-card>
			
			<div v-if="createType == '2'" style="margin: 10px 0;min-height: 60px;">
				<!-- 上传清单附件 -->
				<SingleUpload v-model="filename" @input="getExcel" :tipText="$t('commonInfo.skuFileTips')"
					:preFile="fileTXTName" :orderNo="form.orderNo" class="fileUploadBtn"></SingleUpload>
			</div>
			<div v-if="createType == '1'" style="margin: 10px 0; min-height: 60px;">
				<!-- http://172.18.1.14:8081 -->
				<!-- https://vip.dlvd.com -->
				<el-upload style="display: inline-block;margin-right: 10px;"
				  class="upload-demo fileUploadBtn"
				  :action="$baseUrl + '/toborder/wms/product/addMultiCheckedProduct'"
				  :before-upload="beforeUpload"
				  :on-success="handlesuccess"
					:file-list="preFileList"
				  :on-error="handleError"
					:show-file-list="true"
					:on-preview="handlePreview"
				  :on-progress="handleProgress"
				  multiple
				  accept=".xls, .xlsx"
				  :headers="headers"
				  name="productFile"
				>
				  <el-button type="primary">{{$t('commonInfo.uploadSkuFileName')}}</el-button>
				</el-upload>
				<el-button class="fileUploadBtn"
				  @click="handleDownloadUrl()"
				>{{$t('commonInfo.downloadTemplate')}}</el-button>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleDialogClose" :disabled="submitLoading">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
				<el-button type="primary" :disabled="submitLoading" :loading="submitLoading" @click="checkIsLastOrderNo">{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
			</div>
		</el-dialog>
		<!-- 导入Excel后错误的对话框 -->
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.productInfo')" :visible.sync="dialogErrorVisible" width="640px" :close-on-click-modal="false">
		  <el-table :data="errorData">
		    <el-table-column property="productNm" :label="$t('commonInfo.productName')" align="center"></el-table-column>
		    <el-table-column property="companyProductCode" :label="$t('commonInfo.clientProductCode')" align="center"></el-table-column>
				<el-table-column property="count" :label="$t('commonInfo.count')" align="center"></el-table-column>
				<el-table-column property="errorMsg" :label="$t('commonInfo.result')" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.count != null && scope.row.errorMsg == null" style="color: #0086B3;">{{$t('tipsInfo.success')}}</span>
						<span v-if="scope.row.count == null && scope.row.errorMsg != null" style="color: red;">{{scope.row.errorMsg}}</span>
					</template>
				</el-table-column>
		  </el-table>
		</el-dialog>
		<!-- 查看商品列表 -->
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.productInfo')" :visible.sync="dialogProdcutVisible" width="940px" :close-on-click-modal="false" @close="closeDialog">
		  <el-table :data="checkProductList" v-loading="productLoading">
				<el-table-column property="customerBatchNo" :label="$t('commonInfo.batchNo')" align="center"></el-table-column>
				<el-table-column property="clientProductCode" :label="$t('commonInfo.clientProductCode')" align="center"></el-table-column>
				<el-table-column property="batchProductCount" :label="$t('commonInfo.batchProductCount')" align="center"></el-table-column>
		  </el-table>
			<div class="pageBottom">
				<el-pagination @size-change="handleProductSizeChange" @current-change="handleProductCurrentChange"
					:current-page="productQuery.page" :page-sizes="[10, 20, 30, 40]" :page-size="productQuery.limit"
					layout="total, sizes, prev, pager, next, jumper" :total="productTotal"></el-pagination>
			</div>
		</el-dialog>
		<!-- 添加品名对话框 -->
		<el-dialog :lang="$i18n.locale" :title="$t('chinaWhB2BOrderPage.dialogAddKindnameTitle')" :visible.sync="kindNamePopup" append-to-body width="940px" :close-on-click-modal="false"
			@close="closeDialog" v-loading="loading">
			<el-form ref="kindNameForm" :model="kindNameForm" label-width="180px">
				<el-row class="productRow" v-for="(item, index) in addKindNameList" :key="index" :gutter="4">
					<el-col :span="10">
						<el-form-item :label="$t('chinaWhB2BOrderPage.dialogAddKindname') + '(' + $t('commonInfo.languageZh') +')'">
							<el-input v-model="item.productChineseDesc" :placeholder="$t('tipsInfo.inputPlaceholder') + ' ' + $t('chinaWhB2BOrderPage.dialogAddKindname') + '(' + $t('commonInfo.languageZh') +')'" />
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item :label="$t('chinaWhB2BOrderPage.dialogAddKindname') + '(' + $t('commonInfo.languageEn') +')'">
							<el-input v-model="item.productEnglishDesc" @keyup.enter.native="addItem()"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('chinaWhB2BOrderPage.dialogAddKindname') + ' ' + '(' + $t('commonInfo.languageEn') +')'" />
						</el-form-item>
					</el-col>
					<el-col :span="2">
						<el-button size="medium" type="text" icon="el-icon-circle-plus" style="margin: 0 5px;"
							@click="addItem()" />
						<el-button size="medium" type="text" icon="el-icon-delete" @click="delItem(index)" />
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeDialog" :disabled="addKindNameLoding" >{{$t('commonInfo.dialogBtnCancel')}}</el-button>
				<el-button type="primary" :disabled="addKindNameLoding" :loading="addKindNameLoding" @click="submitNewKindName" style="margin-left:10px;">{{$t('commonInfo.dialogBtnSubmit')}}</el-button>
			</div>
		</el-dialog>
		<!-- 创建修改订单成功显示面单信息 -->
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.orderInfo')" :visible.sync="detailPopup" width="620px" append-to-body :close-on-click-modal="false"
			:before-close="handleCloseDetail">
			<div class="stepthrees">
				<div class="stepthree">
					<div class="three">
						<span>
							{{$t('commonInfo.orderNoColumn') + ':'}}
							<span style="font-size: 18px; color: red">{{ detail_orderNo }}</span>{{'，' + $t('commonInfo.total')}}
							<span style="font-size: 18px; color: red">{{orderCaseNum}}</span> {{$t('commonInfo.case')}}</span>
						<span>{{$t('commonInfo.deliveryCountry') + '：'}}<span
								style="font-size: 18px; color: red">{{detail_destCountry || "------"}}</span></span>
						<span>{{$t('commonInfo.deliveryWarehouse') + '：'}}<span
								style="font-size: 18px; color: red">{{detail_destWhNo == ""?$t('commonInfo.customeWarehouse'):detail_destWhNo || "------"}}</span></span>
						<span>{{$t('commonInfo.deliveryDest') + '：'}}<span
								style="font-size: 18px; color: red">{{detail_otherDest || "------"}}</span></span>
						<span>{{$t('commonInfo.declaredValue') + '(USD)：'}}<span
								style="font-size: 18px; color: red">{{detail_declaredValue}}</span></span>
						<span>{{$t('chinaWhB2BOrderPage.szDlvdWarehouseAddress') + ':'}}
							<ul style="list-style: none">
								<li style="color: red; font-size: 18px">{{whAddress}}</li>
								<li>
									{{$t('commonInfo.contactMan') + ':' + whContactMan}}
									<span>({{userName}})</span>
								</li>
								<li>{{$t('commonInfo.contact') + ':' + whContactPhone}}</li>
							</ul>
						</span>
						<el-row class="table-grid-content">
							<el-col v-show="frontOrafter == '1'" :span="2" class="grid" :gutter="15"
								style="width: 100%; text-align: center">
								<el-button type="primary" v-if="PdfLoading" :loading="PdfLoading">{{$t('tipsInfo.downloadTips')}}</el-button>
								<el-button v-else type="primary" class="btn-right" @click="getPdf"
									:disabled="PdfLoading">
									{{$t('commonInfo.actionDownloadPDF')}}
								</el-button>
							</el-col>
							<el-col v-show="frontOrafter == '0'" :span="2" class="grid" :gutter="15"
								style="width: 100%; text-align: center">
								<el-button type="info" class="btn-right" :loading="true">{{$t('chinaWhB2BOrderPage.orderReviewTips')}}
								</el-button>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 轨迹对话窗 -->
		<el-dialog
		  :lang="$i18n.locale"
		  :title="$t('commonInfo.trackingTitle')"
		  :visible.sync="popups"
		  width="880px"
		  append-to-body
			:close-on-click-modal="false"
		  class="timeline"
		  :before-close="handleDialogClose"
		>
		  <!-- <div style="text-align:right;font-size:18px;color:red">
		    <span>{{this.onRemarks}}</span>
		    <span v-show="this.isShow">：</span>
		    <span>{{this.warehouseTime}}</span>
		  </div> -->
		  <div class="dialog_orderNo">
		  	<label>{{rowOrderNo}}</label>
				<label v-show="dlvdMainDeliveryTime != ''">{{$t('commonInfo.appointmentTime') + '：' + dlvdMainDeliveryTime}}</label>
		  </div>
		  <el-timeline>
		  	<el-timeline-item v-for="(activity, index) in mainActiveData" :key="index" color="green"
		  		:timestamp="(activity.initTime)" placement="top" v-show="activity.status==1000?false:true">
		  		<el-card v-if="activity.status != 'batchInfo'">
		  			<!-- 轨迹区域 -->
		  			<h4>{{activity.description}}</h4>
		  			<!-- location -->
		  			<p v-show="activity.location==false?false:activity.location==null?false:true">
		  				{{$t('commonInfo.trackLocation') + ':'}}
		  				<span style="font-weight:700">{{activity.location}}</span>
		  			</p>
		  			<!-- 备注 -->
		  			<p>
		  				{{$t('commonInfo.remark') + ':'}}
		  				<span style="font-weight:700">{{activity.remarks}}</span>
		  			</p>
		  		</el-card>
		  		<!-- 分批信息 -->
		  		<el-card v-if="activity.status == 'batchInfo'">
		  		  <el-row style="background-color: #f9f9f9; margin-bottom: 15px;border-radius: 10px;padding-top: 20px;" v-for="(subOrderData,subOrderIndex) in subActiveData" :key="subOrderIndex">
		  		    <div class="dialog_subOrderNo">
		  		      <label>{{$t('commonInfo.batch')+': '+subOrderData[0].esnadTobOrderNo}}</label>
								<div style="display: inline-flex;" v-for="(trackItem,trackIndex) in subOrderData" :key="trackIndex">
								  <label style="color: #5981c9;font-size: 16px;margin-left: 200px;" v-if="trackItem.status == '1000'">{{$t('commonInfo.appointmentTime') + '：' + trackItem.description}}</label>
								</div>
		  		    </div>
		  		    <!-- 子订单轨迹 -->
		  		    <el-timeline>
		  		      <el-timeline-item v-for="(subActivity,subIndex) in subOrderData" :key="subIndex" color="#cbac39"
		  		      :timestamp="(subActivity.initTime)" placement="top" v-show="subActivity.status==1000?false:true">
		  		        <p style="font-size:16px">
		  		          <span style="font-weight:700">{{subActivity.remarks}}</span>
		  		        </p>
		  		        <!-- 轨迹区域 -->
		  		        <h4 style="color:#666;font-size:16px">
		  		          [ {{subActivity.location}} ]
		  		          {{subActivity.description}}
		  		        </h4>
		  		      </el-timeline-item>
		  		    </el-timeline>
		  		  </el-row>
		  		</el-card>
		  	</el-timeline-item>
		  </el-timeline>
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="handleDialogClose">{{$t('commonInfo.dialogBtnConfirm')}}</el-button>
		  </div>
		</el-dialog>
		<el-dialog
		  :lang="$i18n.locale"
		  :title="$t('tipsInfo.tip')"
		  :visible.sync="dialogVisible"
		  width="600px">
			<div style="margin: 10px 0; height: 80px;">
				<el-upload
				  class="upload-demo"
				  :action="$baseUrl + '/toborder/wms/product/addMultiCheckedProduct'"
				  :before-upload="beforeUpload"
				  :on-success="handlesuccess"
					:file-list="toSkuPreFileList"
				  :on-error="handleError"
					:show-file-list="true"
					:on-preview="handlePreview"
				  :on-progress="handleProgress"
				  multiple
				  accept=".xls, .xlsx"
				  :headers="headers"
				  name="productFile"
				  style="margin-right:40px; width: 140px; display: inline-block;float: left;"
				>
				  <el-button type="primary">{{$t('commonInfo.uploadSkuFileName')}}</el-button>
				</el-upload>
				<el-button style="float: left;"
				  @click="handleDownloadUrl()"
				>{{$t('commonInfo.downloadTemplate')}}</el-button>
			</div>
		  <div style="padding: 10px;">{{$t('ksaWhBatchListPage.turnToSkuTipOne')}}<span style='color:red'>{{$t('ksaWhBatchListPage.turnToSkuTipTwo')}}</span>{{$t('ksaWhBatchListPage.turnToSkuTipThree')}}</div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false" :disabled="turnSkuLoading">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
		    <el-button type="primary" @click="confirmToSku" :disabled="turnSkuLoading" :loading="turnSkuLoading" >{{turnSkuLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import SingleUpload from "@/components/upload/singleUpload";
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	import {
		formatDate
	} from "@/utils/index";
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
	export default {
		data() {
			//这里存放数据
			return {
				queryParams: {
					orderNo:'',
					page: 1,
					limit: 10,
				},
				tobOrderNo: '',
				tableData: [],
				expandRowArray:[],// 默认展开id数组
				loading: false,
				uploadloading:false,
				total: 0,
				isShow: false,
				popups: false,
				trackingList: [],
				activities: [],
				mainActiveData:[],//主订单轨迹
				subActiveData:[],//子订单轨迹
				warehouseTime: "",
				onRemarks: "",
				frontOrafter: '1',
				arr: [], // 默认展开的行
				
				//新建订单
				DialogLoading: false,
				submitLoading:false,
				orderTitle: '创建订单',
				orderPopup: false,
				form: {
					destCountry: '', //国家
					destCity:'',//城市
					destCountryId: 2,
					warehouseType: '',
					dispatchType:'',
					destWhNo: '', //仓库
					otherDest: '', //地址
					orderNo: '', //订单编号
					contacts: '', //自定义海外仓 联系人
					contactsTel: '', //自定义海外仓 联系电话
					declaredValue:0,//申报金额
					skuCount:0,//SKU总数
					customsDeclarationFlag:false,//是否报关退税 默认false
					clientOrderNo:'',//客户订单号，必填
					clientOperationType:'',//订单类型 - 客户清关方式
				},
				saCityList:[],
				countryList: [{ //国家选项信息
					name: "Saudi Arabia(沙特)",
					value: "SA"
				}, {
					name: "United Arab Emirates(阿联酋)",
					value: "AE"
				}],
				// 1、FBA，2、NOON，3、Fleetan海外仓（中转FBN/FBA），4、Fullfillment，5其他海外仓/私人地址
				warehouseTypeList: [{
					name: "海外仓中转-整箱备货(FBA/NOON)",
					value: '3',
				},{
					name: "Fulfillment 飞坦备货上架",
					value: '4',
				}],
				// 1、FBA，2、NOON，5、海外仓
				dispatchTypeList:[{
					name: "FBA",
					value: '1',
				},{
					name: "NOON",
					value: '2',
				},
				// {
				// 	name: "其他海外仓/私人地址",
				// 	value: '5',
				// }	
				],
				selectWhList: [],
				isEdit: false,
				formData:[],//第二次提交参数
				caseNum: 1, //箱数，可以自动生成箱号
				showCaseDetail:'1',
				fileList: [],
				headers: {},
				imgList:[],//预览POD
				showImg:false,
				successData:[],//上传成功清单数据
				errorData:[],//上传报错返回的数据
				successSkuNum:0,//上传成功清单SKU数量
				successSkuLink:'',//上传成功清单地址
				dialogErrorVisible:false,//显示上传报错数据窗口
				prefixAwbNo: '', //客户五位标识码

				//箱号生成
				caseLoading: false,
				isSpecial: false, //该客户是否自定义填写箱号信息
				clientEnName: '', //客户的英文简称
				isCaseSpecial: false, //是否显示客户自定义填写箱号
				isCaseNormal: true, //是否显示正常的箱号信息
				caseData: [],
				caseDataCustom: [], //显示自定义海外仓的箱号数据
				timeout:null,//防抖定时器
				isHandleSubmit:false,//是否点击提交
				
				// 查看商品列表
				checkProductList:[],
				dialogProdcutVisible:false,
				productLoading:false,
				productQuery:{
					page:1,
					limit:10,
					customerBatchNo:'',
				},
				productTotal:0,
				
				
				createType:"1",//创建订单类型 1为产品上架(fullfillment备货上架)，2为整箱上架(飞坦海外仓转运上架)
				showCarton:false,//根据用户账号显示carton上架功能
				
				filename:'',//SKU文件名
				fileTXTName: [], // 已上传文件集合，通过接口获取文件名和地址url，回传给子组件upload显示该订单已上传的文件列表
				skuListLink:'',//上传SKU文件路径
				//品名
				kindName: [],
				kindNamePopup: false,
				kindNameList: [{ //常用品名
						id: 'common1',
						value: '耳机',
						label: '耳机',
						productChineseDesc: '耳机',
						productEnglishDesc: 'earphone'
					}, {
						id: 'common2',
						value: '手机壳',
						label: '手机壳',
						productChineseDesc: '手机壳',
						productEnglishDesc: 'phone shell'
					}, {
						id: 'common3',
						value: '平板壳',
						label: '平板壳',
						productChineseDesc: '平板壳',
						productEnglishDesc: 'tablet shell'
					}, {
						id: 'common4',
						value: '墙纸',
						label: '墙纸',
						productChineseDesc: '墙纸',
						productEnglishDesc: 'wall paper'
					}, {
						id: 'common5',
						value: '玩具',
						label: '玩具',
						productChineseDesc: '玩具',
						productEnglishDesc: 'toy'
					}, {
						id: 'common6',
						value: '理发器',
						label: '理发器',
						productChineseDesc: '理发器',
						productEnglishDesc: 'haircut machine'
					}, {
						id: 'common7',
						value: '衣服',
						label: '衣服',
						productChineseDesc: '衣服',
						productEnglishDesc: 'clothes'
					}, {
						id: 'common8',
						value: '鼠标套装',
						label: '鼠标套装',
						productChineseDesc: '鼠标套装',
						productEnglishDesc: 'Mouse coordinates'
					},
					{
						id: 'common9',
						value: '咖啡压粉锤',
						label: '咖啡压粉锤',
						productChineseDesc: '咖啡压粉锤',
						productEnglishDesc: 'Coffee Tampers'
					},
					{
						id: 'common10',
						value: '收纳包',
						label: '收纳包',
						productChineseDesc: '收纳包',
						productEnglishDesc: 'Storage bag'
					},
				], //总的品名集合
				addKindNameList: [], //新增品名集合
				addKindNameLoding:false,
				kindNameForm: {},
				
				// 新增订单选择类型窗口
				dialogOrderTypeVisible:false,
				// SKU模板下载
				skuModelUrl:'',
				rowOrderNo:'',
				dlvdMainDeliveryTime:'',
				preFileList:[],
				
				//订单详情窗口
				detailPopup: false,
				detail_orderNo:'',
				detail_destCountry:'',
				detail_destWhNo:'',
				detail_otherDest:'',
				detail_declaredValue:'',
				orderCaseNum:0,
				whAddress:'东莞市塘厦镇桥蛟中路1号好运童园科技园8栋--飞坦数字物流 ',//深圳仓库地址
				whContactMan: '', //仓库联系人
				whContactPhone: '', //仓库联系电话
				userName:'',
				PdfLoading:false,
				
				//转换SKU
				skuParams:{},
				dialogVisible:false,
				toSkuLink:'',
				turnSkuLoading:false,
				toSkuProductList:[],
				toSkuPreFileList:[],
				
				// 2022-5-30 zpy 查看箱信息通过按钮显示弹窗
				packageDialogPopup: false,
				checkPackageOrderNo:'',
				packageData: [],
				packageListLoading: false,
				cartonCountInfo:{
				  inWhCount:0,
				  cancelCount:0,
				},
			};
		},
		//监听属性 类似于data概念
		computed: {
			rules() {// 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
			  return {
					dispatchType: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryType'),
						trigger: 'blur'
					}],
					declaredValue: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.declaredValue'),
						trigger: 'blur'
					}],
					skuCount: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.skuCount'),
						trigger: 'blur'
					}],
					customsDeclarationFlag:[{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('chinaWhB2BOrderPage.customsDeclaration'),
						trigger: 'blur'
					}],
					clientOrderNo:[
						{
							required: true,
							message: this.$t('tipsInfo.inputPlaceholder') + this.$t('chinaWhB2BOrderPage.clientOrderNo'),
							trigger: 'blur'
						}
					]
				}
			}
		},
		//监控data中的数据变化
		watch: {

		},
		components: {
			SingleUpload,
			ElImageViewer,
		},
		//方法集合
		methods: {
			// 获取字典
			async getDict(){
				// 获取B2B空运城市地址 sys_b2bAirCity_list
				const cityRes = await this.$http.get("/toborder/system/dict/data/list",{
				  params: {
						page:1,
						limit:100,
						dictType:'sys_b2bAirCity_list'
					}
				});
				if (cityRes.code === 200) {
					this.saCityList = cityRes.rows;
				} else {
				  this.$message.error(cityRes.msg);
				}
			},
			// 分页条数
			handleSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getlist();
			},
			// 分页页数
			handleCurrentChange(page) {
				this.queryParams.page = page;
				this.getlist();
			},
			// 商品分页条数
			handleProductSizeChange(size){
				this.productQuery.limit = size;
				this.productQuery.page = 1;
				this.getProductList();
			},
			// 商品页数
			handleProductCurrentChange(page){
				this.productQuery.page = page;
				this.getProductList();
			},
			
			rowKey(row) {
			  return row.id;
			},
			handleSearch() {
				this.queryParams.page = 1;
				this.getlist();
			},
			// 重置
			handleReset(){
				this.queryParams = {
					orderNo:'',
					page:1,
					limit:10,
				}
				this.getlist();
			},
			//关闭图片
			closeImg(){
				this.showImg = false;
			},
			//查看POD图片
			checkPOD(url){
				this.imgList = [];
				//显示预览图片　
				if(url.length > 0){
					var that = this;
					that.showImg = true;
					url.forEach(item => {
						that.imgList.push(item);
					})
				}else{
					this.$message.error(this.$t('tipsInfo.noPodInfo'));
				}
			},
			// 查看箱图片
			handleViewCartonImg(url){
				this.imgList = [];
				this.imgList.push(url);
				this.showImg = true;
			},
			// 查看更多备注信息
			handleCheckRemark(row){
				let html = "<span style='word-break:break-word'>" + row.remarks + "</span>";
				this.$alert(html, this.$t('commonInfo.remark'), {
				  confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
				  dangerouslyUseHTMLString: true,
				});
			},
			//添加一条箱号数据
			addCaseItem(item) {
				let num1 = item.input.substr(-3);
				let beforeNum = item.input.substr(0, (item.input.length - 3))
				let num2 = ('00' + (parseInt(num1) + 1)).substr(-3);
				this.getCaseNum(1, num2)
			},
			// 增加custom箱号数据
			addCustomCaseItem(item) {
				let num = ('00' + (parseInt(item.nextNum.split('-')[1]) + 1)).substr(-3);
				this.caseDataCustom.push({
					preNum: item.preNum,
					input: '',
					nextNum: item.nextNum.split('-')[0] + '-' + num,
				})
			},
			//删除选择的箱号数据
			delCaseItem(row, index) {
				this.$confirm(this.$t('tipsInfo.deleteRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						// 判断是不是编辑
						if (row.isDetail) {
							this.$http
								.delete("/toborder/delete/lgpackage", {
									data: {
										esnadTobOrderNo: this.form.orderNo,
										esnadLgPackageNo: row.order
									}
								})
								.then(res => {
									if (res.code === 200) {
										let indexs = this.caseData.findIndex(
											item => item.index === row.index
										);
										this.caseData.splice(indexs, 1);
										this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
									} else {
										this.$message.error(res.msg);
									}
									if (this.caseData.length == 0 && this.caseDataCustom.length == 0) {
										this.getCaseNum(1, '');
									}
								});
						} else {
							this.caseData.splice(index, 1);
							this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
							if (this.caseData.length == 0 && this.caseDataCustom.length == 0) {
								this.getCaseNum(1, '');
							}
						}
			
			
					})
					.catch(() => {});
			},
			// 根据状态码显示信息 中英文
			returnStatusName(row){
				let name = '';
				switch(row.status){
					case '101': name = this.$t('B2BStatus.creating'); break;
					case 'n100': name = this.$t('B2BStatus.init'); break;
					case 'n101': name = this.$t('B2BStatus.warehousing'); break;
					case 'n200': name = this.$t('B2BStatus.cnReceive'); break;
					case 'n240': name = this.$t('B2BStatus.cnPartialShipment'); break;
					case 'n300': name = this.$t('B2BStatus.cnOutscan'); break;
					case '-300': name = this.$t('B2BStatus.cnOutscanExceptionPart'); break;
					case '-302': name = this.$t('B2BStatus.cnTransitExceptionCheck'); break;
					case 'n301': name = this.$t('B2BStatus.cnAir'); break;
					case '-305': name = this.$t('B2BStatus.cnTransitException'); break;
					case '307': name = this.$t('B2BStatus.cnTransitExceptionOr'); break;
					case '-310': name = this.$t('B2BStatus.cnAirExceptionDelay'); break;
					case '-311': name = this.$t('B2BStatus.cnAirExceptionCancel'); break;
					case 'n302': name = this.$t('B2BStatus.airArrived'); break;
					case 'n400': name = this.$t('B2BStatus.customsClearanceCompLated'); break;
					case '-401': name = this.$t('B2BStatus.customsClearanceComplatedExceptionCheck'); break;
					case '-402': name = this.$t('B2BStatus.customsClearanceComplatedExceptionDeduction'); break;
					case 'n510': name = this.$t('B2BStatus.overseasArrived'); break;
					case 'n500': name = this.$t('B2BStatus.ksaInscan'); break;
					case '-520': name = this.$t('B2BStatus.cartonToSku'); break;
					case 'n699': name = this.$t('B2BStatus.ksaReadyForDispatch'); break;
					case 'n700': name = this.$t('B2BStatus.delivering'); break;
					case '-700': name = this.$t('B2BStatus.deliveryFailed'); break;
					case 'n710': name = this.$t('B2BStatus.partialreceipt'); break;
					case 'n800': name = this.$t('B2BStatus.successfulDelivery'); break;
					case '-800': name = this.$t('B2BStatus.signingFailed'); break;
					case '-100': name = this.$t('B2BStatus.cancelOrder'); break;
					case '-600': name = this.$t('B2BStatus.transferThirdpartyDelivery'); break;
					// 未找到或者新加状态默认显示后台返回状态名称
					default: name = row.memo; break;
				}
				return name
			},
			//删除选择的箱号custom数据
			delCaseCustomItem(row, index) {
				this.$confirm(this.$t('tipsInfo.deleteRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						// 判断是不是编辑
						if (row.isDetail) {
							this.$http
								.delete("/toborder/delete/lgpackage", {
									data: {
										esnadTobOrderNo: this.form.orderNo,
										esnadLgPackageNo: row.order
									}
								})
								.then(res => {
									if (res.code === 200) {
										let customIndex = this.caseDataCustom.findIndex(
											item => item.index === row.index
										);
										this.caseDataCustom.splice(customIndex, 1);
			
										this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
									} else {
										this.$message.error(res.msg);
									}
									if (this.caseData.length == 0 && this.caseDataCustom.length == 0) {
										this.getCaseNum(1, '');
									}
								});
						} else {
							this.caseDataCustom.splice(index, 1);
							this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
							if (this.caseData.length == 0 && this.caseDataCustom.length == 0) {
								this.getCaseNum(1, '');
							}
						}
			
					})
					.catch(() => {});
			},
			// 追踪页面
			async xiang(id) {
			  // this.$router.push({ path: "/Tracking?id=" + id });
				const res = await this.$http.get(
				  "/toborder/trackinfo/" + id
				);
				this.rowOrderNo = id;
				if (res.code === 200) {
				  this.activities = res.data;
					this.mainActiveData = this.activities.mainOrderTrackingList;
					this.subActiveData = this.activities.subOrderTrackingList;
				  this.popups = true;
					// 增加分批轨迹信息 如果有返回子订单轨迹则再主订单轨迹入库完成后面添加对象，来显示子订单分批信息 zpy 2022-7-29
					if(this.subActiveData.length > 0){
					  let index = this.mainActiveData.findIndex((item) => item.status == 'n200');
					  this.mainActiveData.splice(index,0,{
					    initTime:this.$t('chinaWhB2BOrderPage.batchTrackingTitle'),
					    status:'batchInfo',
					  })
						this.subActiveData.forEach(item => {
							item.forEach(subItem => {
								subItem.initTime = subItem.initTime + ' Time Zone：Beijing';
							})
							
						})
					}
				  if (this.mainActiveData.length === 0) {
				    this.popups = false;
				    //输入的订单号不存在
				    this.$message.error(this.$t('tipsInfo.orderNoExistTips'));
				  }
				  this.mainActiveData.forEach(item => {
						if(item.status == '1000'){
						  this.dlvdMainDeliveryTime = item.description
						}
				    if (item.status == 1000 && item.id) {
				      this.warehouseTime = item.description;
				      this.onRemarks = item.remarks;
				      this.isShow = true;
				    }
						if(item.initTime != this.$t('chinaWhB2BOrderPage.batchTrackingTitle')){
							item.initTime = item.initTime + ' Time Zone：Beijing';
						}
						// item.initTime = formatDate(item.initTime);
				//     let indexLocation = item.location.indexOf(":");
				//     item.location = item.location.substring(
				//       indexLocation + 1,
				//       item.location.length
				//     );
				
				//     let indexRemarks = item.remarks.indexOf(":");
				//     item.remarks = item.remarks.substring(
				//       indexRemarks + 1,
				//       item.remarks.length
				//     );
				  });
				}
			},
			//清单附件下载
			skuSheet(row) {
			  window.open(row.skuListLink);
			},
			// 下载PDF面单
			async expressSheet() {
				let that = this;
				this.PdfLoading = true;
				let res = await this.$http.get(
					"/toborder/download/waybill/" + this.form.orderNo
				);
				if (res.code === 200) {
					this.pdfUrl = res.data;
				} else {}
				setTimeout(function(){
					that.PdfLoading = false;
				},100)
			},
			isShowPDF(row) {
				let isShow = false;
				if (this.frontOrafter == '0') { //前置审核
					if (row.examineFlag == false) { //未审核
						isShow = false;
					} else { //1审核通过
						isShow = true;
					}
				} else { // 1后置审核
					isShow = true;
				}
				return isShow;
			},
			// 点击展开或收起分批信息
			handleExpandRow(row){
				row.expand = row.expand?false:true;
				this.$refs.b2bOrderTable.toggleRowExpansion(row);
			},
			showOrderDetail(){
				this.detailPopup = true;
				this.detail_orderNo = this.form.orderNo;
				this.detail_declaredValue = this.form.declaredValue;
				this.detail_destCountry = this.form.destCountry;
				this.detail_destWhNo = this.form.destWhNo;
				this.detail_otherDest = this.form.otherDest;
				// 生成下载面单
				this.expressSheet();
				this.getAddress();
			},
			PDFSheet(row) {
				if(row == null){
					this.$message.warning(this.$t('chinaWhB2BOrderPage.downloadPdfFailedTips'));
				}else{
					window.open(row);
				}
			},
			// 下载paf
			getPdf() {
				window.open(this.pdfUrl, "_blank");
			},
			// 列表更多操作
			handleCommand(val) {
			  let row = val.row;
			  // 判断操作按钮
			  switch (val.command) {
			    // 修改订单
			    case 'a':
			      this.editOrder(row);
			      break;
			    // 转SKU
			    case 'b':
			      this.toSku(row);
			      break;
					// 查看清单
					case 'c':
					  this.CheckSkuList(row);
					  break;
					// 下载清单
					case 'd':
					  this.skuSheet(row);
					  break;
					// 显示分批信息
					case 'e':
					  this.handleExpandRow(row);
					  break;
			  }
			},
			
			//获取仓库地址
			async getAddress(){
				const resSa = await this.$http.get("/toborder/system/dict/data/list?dictType=sys_szwh_address");
				if(resSa.code == 200){
					this.whAddress = resSa.rows[0].dictValue;
					this.whContactMan = resSa.rows[0].remark.split('###')[0];
					this.whContactPhone = resSa.rows[0].remark.split('###')[1];
				}else{
					this.$message.error("获取仓库地址失败");
				}
			},
			isShowPod(row){//判断数据状态
			  let isShow = false;
				let statusNumber = row.status.substr(1);
				if(parseInt(statusNumber) >= 500){
					if(row.podLink != null && row.podLink != ''){
						isShow = true;
					}
				}
				return isShow
			},
			// 上传文件
			getExcel(data) {
				this.skuListLink = data;
				//修改upload的preview预览
				if (data != '') {
					let str = data.split("~");
					this.fileTXTName = [{
						name: str[1],
						url: data,
					}]
				}
				// console.log(data);
			},
			//获取品名列表
			async getKindNameList() {
				const res = await this.$http.get("/toborder/productname/list");
				if (res.code == 200) {
					if (res.data.length >= 0) {
						res.data.forEach((item, index) => {
							this.kindNameList.push({
								id: item.id,
								value: item.productChineseDesc,
								label: item.productChineseDesc,
								productChineseDesc: item.productChineseDesc,
								productEnglishDesc: item.productEnglishDesc
							})
						})
						this.kindNameList = this.quchong(this.kindNameList);
					}
				}
			},
			closeDialog() {
				this.kindNamePopup = false;
				this.dialogProdcutVisible = false;
				this.productQuery = {
					page:1,
					limit:10,
					customerBatchNo:'',
				}
			},
			//去重数组，以中文描述为准
			quchong(arr) {
				const res = new Map();
				return arr.filter(
					(list) => !res.has(list.productChineseDesc) && res.set(list.productChineseDesc, 1)
				);
			},
			openKindName() {
				this.addKindNameList = [];
				this.addItem();
				this.kindNamePopup = true;
			},
			// 动态添加一行
			addItem() {
				this.addKindNameList.push({
					productChineseDesc: "",
					productEnglishDesc: "",
				});
			},
			// 其他杂费删除一行
			delItem(index) {
				this.addKindNameList.splice(index, 1);
				// console.log(index);
			},
			//新增品名
			submitNewKindName() {
			
				let isEmpty = false;
				let kindNameData = [];
				this.addKindNameList.forEach((item, index) => {
					if (item.productChineseDesc != '' && item.productEnglishDesc != '') {
						kindNameData.push(item);
					}
					if (item.productChineseDesc != '' && item.productEnglishDesc == '') {
						isEmpty = true;
					}
					if (item.productChineseDesc == '' && item.productEnglishDesc != '') {
						isEmpty = true;
					}
					if (item.productChineseDesc == '' && item.productEnglishDesc == '') {
						isEmpty = true;
					}
				})
				if (isEmpty) {
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.dialogAddKindnameSubmitTips'));
				}
				this.addKindNameLoding = true;
				this.$http.post("/toborder/productname/add", kindNameData).then(res => {
					if (res.code == 200) {
						kindNameData.forEach((item, index) => {
							this.kindNameList.push({
								id: index + '_add',
								value: item.productChineseDesc,
								label: item.productChineseDesc,
								productChineseDesc: item.productChineseDesc,
								productEnglishDesc: item.productEnglishDesc
							})
							this.kindName.push(item.productChineseDesc);
						})
						this.addKindNameLoding = false;
						this.$message.success(this.$t('tipsInfo.success'));
						this.kindNamePopup = false;
					} else {
						this.addKindNameLoding = false;
						this.$message.error(this.$t('tipsInfo.failed'));
					}
				}).catch(err => {
					this.addKindNameLoding = false;
					this.$message.error(this.$t('tipsInfo.failed'));
				})
			},
			
			// 添加订单，先打开选择订单类型窗口
			addOrder() {
				this.dialogOrderTypeVisible = true;
				this.caseNum = 1;
				this.isEdit = false;
				this.formData = [];
				this.successData = [];//上传成功清单数据
				this.errorData = [];//上传报错返回的数据
				this.successSkuNum = 0;//上传成功清单SKU数量
				this.successSkuLink = '';
				this.preFileList = [];
			},
			//创建中和已下单可以修改
			isEditOrder(row) {
				if (row.status == 'n100' || row.status == '101') {
					return true;
				} else {
					return false;
				}
			},
			// 修改订单
			editOrder(row){
				if(row.warehouseType == '4'){//产品上架
					this.createType = '1';
				}else if(row.warehouseType == '3'){//整箱上架
					this.createType = '2';
					// this.form.dispatchType = '';
				}
				this.isEdit = true;
				this.orderTitle = this.$t('commonInfo.modifyOrder');
				this.getOrderInfo(row);
				this.orderPopup = true;
			},
			// 整箱上架类型转换为SKU上架，该操作只提交申请，需审核操作通过才会转换
			toSku(row){
				this.skuParams = {
					clientId:this.prefixAwbNo,
					boxAmount:row.packageAmount,
					customerBatchNo:row.esnadTobOrderNo,
				}
				this.dialogVisible = true;
				this.toSkuLink = '';
				this.toSkuProductList = [];
				this.toSkuPreFileList = [];
				if(this.skuModelUrl == ''){
					this.getModelUrl();
				}
			},
			//确认转换SKU
			async confirmToSku(){
				if(this.toSkuLink == ''){
					return this.$message.warning(this.$t('tipsInfo.submitOrderSkuListFileTips'));
				}
				let productList = [];
				this.toSkuProductList.forEach(item => {
					productList.push({
						companyProductCode:item.companyProductCode,  //sku
						registerCount:item.count,    //数量
					})
				})
				
				this.skuParams.productList = productList;
				this.skuParams.skuListLink = this.toSkuLink;
				this.turnSkuLoading = true;
				const res = await this.$http.post("/toborder/wms/carton/toSku",this.skuParams);
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.dialogVisible = false;
					this.turnSkuLoading = false;
					this.getlist();
				} else {
					this.turnSkuLoading = false;
				  this.$message.error(res.msg);
				}
			},
			// 选择订单类型，打开创建订单窗口，根据类型显示信息
			ChooseCreateType(type){
				this.createType = type;
				this.dialogOrderTypeVisible = false;
				let list = this.saCityList.filter(item => item.dictValue == '1');
				this.form.destCity = list[0].dictLabel;
				this.orderTitle = this.$t('commonInfo.createOrder');
				this.orderPopup = true;
				//获取SKU模板
				if(type == '1'){
					this.getModelUrl();
				}
				
				this.getOrder();
				this.getSaInfo();
			},
			async getModelUrl(){
				const res = await this.$http.get("/toborder/system/dict/data/list",{
				  params: {
						page:1,
						limit:10,
						dictType:'sys_template_url'
					}
				});
				
				if (res.code === 200) {
					let that = this;
					let data = res.rows;
					data.forEach(item => {
						if(item.dictLabel == 'fullfillment备货清单模板'){
							that.skuModelUrl = item.remark;
						}
					})
				} else {
				  this.$message.error(res.msg);
				}
			},
			// 下载清单模板
			handleDownloadUrl(){
				window.open(this.skuModelUrl);
			},
			// 通过点击按钮显示箱信息弹窗
			handleCheckPackageInfo(row) {
				this.packageData = [];
				this.packageDialogPopup = true;
				this.checkPackageOrderNo = row.esnadTobOrderNo;
				this.getPackageList(row.esnadTobOrderNo);
			},
			// 获取箱信息列表
			async getPackageList(id) {
				this.packageListLoading = true;
				const res = await this.$http.get(
					"/toborder/clientlgpackage/info/" + id
				);
				if (res.code == 200) {
					this.packageData = res.data.detailList;
					this.cartonCountInfo.inWhCount = res.data.inWhCount;
					this.cartonCountInfo.cancelCount = res.data.cancelCount;
					this.packageListLoading = false;
				} else {
					this.$message.error(res.msg);
					this.packageListLoading = false;
				}
			},
			// 获取重量信息合计
			getSummaries(param) {
				const { columns, data	} = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
					  sums[index] = this.$t('commonInfo.secondTotal');
			      return;
			    }
					if(column.label == this.$t('commonInfo.grossWeight') + '(KG)'){
						let gwSum = 0;
						data.map(item => {
							let gwValue = item.grossWeight == null ? 0:item.grossWeight;
							gwSum += Number((gwValue).toFixed(2));
						});
						sums[index] = gwSum ==0?'':gwSum.toFixed(2);
					} else if(column.label == this.$t('commonInfo.chargeWeight') + '(KG)'){
						let cwSum = 0;
						data.map(item => {
							let cwValue = item.chargedWeight == null ? 0:item.chargedWeight;
							cwSum += Number((cwValue).toFixed(2));
						});
						sums[index] = cwSum == 0?'':cwSum.toFixed(2);
					} else{	
						sums[index] = '';
					}
				});
				return sums;
			},
			// 是否显示展开信息 根据增加classname去区分判断显示展开按钮
			setClassName({row,index}){
			  let classname = 'expand';
			  // 默认不显示展开列，在操作列控制显隐 zpy 2022-8-5
			  // if(row.subOrderList == null || row.subOrderList.length == 0){
			  //     classname = 'expand';
			  // }
			  return classname
			},
			// 获取箱数据
			async changeEve(row) {
				console.log(row);
			  if (this.arr[0]) {
			    if (this.arr[0] === row.id) {
			      return (this.arr = []);
			    }
			  }
			  this.arr = [];
			  const res = await this.$http.get(
			    "/toborder/clientlgpackage/info/" + row.esnadTobOrderNo
			  );
			
			  if (res.code === 200) {
			    row.datas = res.data;
			    // console.log(res.data);
			
			    let obj = Object.assign({}, row);
			    let oIndex = this.tableData.findIndex(item => item.id == row.id);
			    this.$set(this.tableData, oIndex, obj);
			    this.arr = [row.id];
			  } else {
			    this.$message.error(res.msg);
			  }
			},
			// 获取列表
			async getlist() {
				this.loading = true;
				this.tableData = [];
				const res = await this.$http.get("/toborder/wms/batch/list", {
					params: this.queryParams
				});
				// console.log(res);

				if (res.code === 200) {
					let data = res.data.list;
					// 总共有多少条数据，分页
					this.total = res.data.totalCount;
					let index = 1;
					data.forEach(item => {
					  item.id = index += 1;
						item.expand = false;
					  item["datas"] = [];
					  // console.log(item);
					});
					this.loading = false;
					this.tableData = data;
				} else {
					this.loading = false;
					this.$message.error(res.msg);
				}
			},
			// 弹出层x号关闭
			handleDialogClose() {
				// alert(1);
				this.popups = false;
				this.orderPopup = false;
				this.isShow = false;
				this.warehouseTime = "";
				this.onRemarks = "";
				this.caseNum = 0;
				this.caseData = [];
				this.caseDataCustom = [];
				this.isHandleSubmit = false;
				this.form = {
					destCountry: '', //国家
					destCity:'',//城市
					destCountryId: 2,
					warehouseType: '',
					dispatchType:'',
					destWhNo: '', //仓库
					otherDest: '', //地址
					orderNo: '', //订单编号
					contacts: '', //自定义海外仓 联系人
					contactsTel: '', //自定义海外仓 联系电话
					declaredValue:0,//申报金额
					skuCount:0,//SKU总数
					customsDeclarationFlag:false,//是否报关退税 默认false
					clientOrderNo:'',//客户订单号，必填
					clientOperationType:'',//订单类型 - 客户清关方式
				};
				this.kindName = [];
				this.skuListLink = '';
				this.fileTXTName = [];
				this.dlvdMainDeliveryTime = '';
			},
			//查看清单列表
			CheckSkuList(row) {
				this.productQuery.customerBatchNo = row.esnadTobOrderNo;
				this.dialogProdcutVisible = true;
				this.getProductList();
			},
			async getProductList(){
				let that = this;
				this.checkProductList = [];
				this.productLoading = true;
				const res = await this.$http.get(
				  "/toborder/wms/batch/listProduct",{
					params: this.productQuery
				});
				if (res.code == 200) {
					this.productTotal = res.data.totalCount;
					this.checkProductList = res.data.list;
					this.productLoading = false;
				} else {
					this.productLoading = false;
				  this.$message.error(res.msg);
				}
			},
			//限制输入数字
			handleNum() {
				this.caseNum = (this.caseNum.toString()).replace(/[^\d]/g, '');
				//防抖
				if (this.timeout) {
					clearTimeout(this.timeout)
				}
				this.timeout = setTimeout(() => {
					this.caseData = [];
					this.caseDataCustom = [];
					this.getCaseNum(this.caseNum,'');
				}, 500);
			},
			// 获取订单号
			async getOrder() {
				const res = await this.$http.post("/toborder/create/0");
				if (res.code === 200) {
					this.form.orderNo = res.data;
					this.getCaseNum(this.caseNum, ''); //获取箱号
				} else {
					this.$message.error(this.$t('tipsInfo.createOrderNoFailedTips'));
				}
			},
			//获取订单信息
			async getOrderInfo(row) {
				await this.$http.get("/toborder/detail/" + row.esnadTobOrderNo).then(res => {
					if (res.code == 200) {
						
						this.form.orderNo = row.esnadTobOrderNo;
						this.form.declaredValue = res.data.toBOrderInfo.declaredValue;
						this.form.skuCount = res.data.toBOrderInfo.productAmount;
						this.form.destCountry = res.data.toBOrderInfo.destCountry;
						this.form.warehouseType = res.data.toBOrderInfo.warehouseType;
						this.form.destWhNo = res.data.destWhNo;
						this.form.otherDest = res.data.toBOrderInfo.otherDest;
						this.form.contacts = res.data.toBOrderInfo.contacts;
						this.form.contactsTel = res.data.toBOrderInfo.contactsTel;
						this.form.clientOrderNo = res.data.toBOrderInfo.clientOrderNo;
						this.form.destCity = res.data.destCity;
						this.form.customsDeclarationFlag = res.data.toBOrderInfo.customsDeclarationFlag;
						if(this.createType == '1'){
							this.successSkuLink = res.data.toBOrderInfo.skuListLink;
							this.preFileList = [{
								name: this.$t('commonInfo.actionCheckFile'),
								url:this.successSkuLink,
							}]
						}else{
							this.form.dispatchType = res.data.dispatchType;
							this.skuListLink = res.data.toBOrderInfo.skuListLink;
							this.fileTXTName = [{
								name: this.$t('commonInfo.actionCheckFile'),
								url: this.skuListLink,
							}]
						}
						//预选品名
						if (res.data.toBOrderInfo.productChineseDesc != null) {
							if (res.data.toBOrderInfo.productChineseDesc.split(',').length != 0) {
								this.kindName = res.data.toBOrderInfo.productChineseDesc.split(
								',');
							}
						}
						//显示箱号
						this.setDetail(res.data.lgPackageList);
					} else {
						this.$message.error(res.msg);
					}
				})


			},
			// 修改循环添加 table
			setDetail(OrderCaseList) {
				let that = this;
				that.caseData = [];
				if (OrderCaseList != null && OrderCaseList.length != 0) {
					if (OrderCaseList.length > 0) {
						that.caseNum = OrderCaseList.length;
						that.isCaseNormal = true;
						OrderCaseList.forEach((item, i) => {
							that.caseData.push({
								index: i,
								input: item.clientLgPackageNo,
								order: item.esnadLgPackageNo,
								isDetail: true
							});
						});
					}
					that.caseLoading = false;
				} else { //如果是空的箱集合，则根据输入的箱数自动填充箱号
					that.caseLoading = false;
					that.getCaseNum(1, '');
				}
			},
			//获取并填充海外仓信息
			async getSaInfo() {
				this.form.destCountry = this.countryList[0].value;
				if(this.createType == '1'){
					this.form.warehouseType = this.warehouseTypeList[1].value;
				}else if(this.createType == '2'){
					this.form.warehouseType = this.warehouseTypeList[0].value;
				}
				//获取字典的飞坦海外仓信息
				await this.$http.get("/toborder/system/dict/data/list?dictType=sys_SA_FullfillmentWh").then(res => {
					if (res.code == 200) {
						this.selectWhList = res.rows;
						this.form.destWhNo = this.selectWhList[0].dictValue;
						let info = this.selectWhList[0].remark.split('###');
						this.form.otherDest = info[0];
						this.form.contacts = info[1];
						this.form.contactsTel = info[2];
						if (this.form.contacts == 'Fleetan-') {
							this.form.contacts = this.form.contacts + this.prefixAwbNo;
						}
					} else {
					}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},
			// 自动填充该箱填写信息到后续箱信息
			autoFillCaseInfo(data,i){
				let info = this.caseDataCustom[i].input;
				this.caseDataCustom.forEach((item,index) => {
					if(index >= i){
						item.input = info;
					}
				})
				this.handleInputCustomCase();
			},
			// 监听自定义箱号输入信息
			handleInputCustomCase(){
				// 点击提交后才监听每一次的输入是否正确
				if(this.isHandleSubmit){
					this.handleCheckCaseInfo(this.caseDataCustom);
				}
			},
			
			//amazon:填写FBA号：FBA号U001，未填写FBA号：FBX-年月日订单号后四位-001
			//noon:需要判断是否已经填写ASN号，填写：asn号-001，未填写：NOON-年月日订单号后四位-001
			//自定义海外仓：CUSTOM-年月日订单号后四位-001
			getCaseNum(caseNum, addNum) {
				this.caseLoading = true;

				if (caseNum <= 0) {
					caseNum = 1;
				}
				let nowTime = new Date();
				let caseTime = nowTime.getFullYear().toString() + (nowTime.getMonth() + 1).toString() + nowTime.getDate()
					.toString() + nowTime.getHours().toString() + nowTime.getMinutes().toString() + nowTime.getSeconds()
					.toString();
				for (let i = 0; i < caseNum; i++) {
					let num = '';
					if (addNum == '') {
						num = ('00' + (i + 1)).substr(-3);
					} else {
						num = addNum;
					}
					if (this.isSpecial == true) {
						this.isCaseSpecial = true;
						this.caseDataCustom.push({
							preNum: this.prefixAwbNo,
							input: this.form.clientOrderNo,
							nextNum: this.form.orderNo.substr(-4) + '-' + num,
							isNull:false,
							color:'white',
						})
					} else {
						this.isCaseNormal = true;
						this.caseData.push({
							input: this.prefixAwbNo + this.clientEnName + this.form.orderNo.substr(-4) + '-' +
								num,
						});
					}
				}
				this.caseNum = this.caseData.length + this.caseDataCustom.length;
				
				this.caseLoading = false;
			},
			handleCloseDetail() {
				this.detailPopup = false;
				this.pdfUrl = '';
				// 关闭更新列表，同步生成的面单链接
				this.getlist();
			},
			
			// 检测箱信息填写是否完整
			handleCheckCaseInfo(data){
				let isAllRight = true;
				data.forEach((item,index) => {
					if(item.input == ''){
						item.color = '#e3cd85';
						isAllRight = false;
					}else{
						item.color = 'white';
					}
					this.$set(this.caseDataCustom,index,item);
				})
				return isAllRight;
			},
			// 检验当前订单号是否最新，防止客户打开两个浏览器且都打开下单窗口，都提交导致一个订单号出现两个面单情况
			async checkIsLastOrderNo() {
				this.submitLoading = true;
				if(!this.isEdit){
					let currentOrderNo = this.form.orderNo;
					// 获取当前最新订单号
					const res = await this.$http.post("/toborder/create/0");
					if (res.code === 200) {
						this.form.orderNo = res.data;
						// 订单号对不上，需要更新数据
						if(currentOrderNo != this.form.orderNo){
							// 自定义箱号
							if(this.isSpecial == true){
								this.caseDataCustom.forEach(item => {
									let str = this.form.orderNo.substr(-4);
									let num = item.nextNum.split('-')[1];
									item.nextNum = str + '-' + num;
								})
							}else{
								this.caseData.forEach(item => {
									let str = this.prefixAwbNo + this.clientEnName + this.form.orderNo.substr(-4);
									let arr = item.input.split('-');
									let num = arr[arr.length - 1];
									item.input = str + num;
								})
							}
						}
						this.submitUpdateInfo();
					} else {
						this.submitLoading = false;
						this.$message.error(this.$t('tipsInfo.createOrderNoFailedTips'));
					}
				}else{
					this.submitUpdateInfo();
				}
			},
			submitUpdateInfo() {
				let that = this;
				//第二个接口参数
				let obj = {
					clientLgPackageList: [],
					esnadTobOrderNo: this.form.orderNo,
					clientOrderNo:this.form.clientOrderNo,
				};
				let productList = [];
				//处理品名字段
				this.formData.productChineseDesc = '';
				this.formData.productEnglishDesc = '';
				//是否上传清单 分类型判断
				if(this.createType == '1'){
					if(this.successData.length == 0){
						this.submitLoading = false;
						return this.$message.warning(this.$t('tipsInfo.submitOrderSkuListFileTips'));
					}
					this.formData.skuCount = this.successSkuNum;
					obj.skuListLink = this.successSkuLink;
					this.successData.forEach(item => {
						this.formData.productChineseDesc += item.productNm + ',';
						this.formData.productEnglishDesc += item.productNm + ',';
						productList.push({
							companyProductCode:item.companyProductCode,  //sku
							registerCount:item.count,    //数量
						})
					})
					// 如果品名超过1000的字符，则不获取后面信息，后台长度限制
					if(this.formData.productChineseDesc.length > 1000){
						this.formData.productChineseDesc = this.formData.productChineseDesc.substr(0,1000);
						this.formData.productEnglishDesc = this.formData.productEnglishDesc.substr(0,1000);
					}
				}else{
					if (this.skuListLink == '') {
						this.submitLoading = false;
						return this.$message.warning(this.$t('tipsInfo.submitOrderSkuListFileTips'));
					} else {
						obj.skuListLink = this.skuListLink;
					}
					this.formData.skuCount = this.form.skuCount;
					if (this.kindName.length != 0) {
						this.kindName.forEach(item => {
							that.kindNameList.forEach(item1 => {
								if (item1.productChineseDesc == item) {
									that.formData.productChineseDesc += item1.productChineseDesc + ',';
									that.formData.productEnglishDesc += item1.productEnglishDesc + ',';
								}
							})
						})
						// 如果品名超过1000的字符，则不获取后面信息，后台长度限制
						if(this.formData.productChineseDesc.length > 1000){
							this.formData.productChineseDesc = this.formData.productChineseDesc.substr(0,1000);
							this.formData.productEnglishDesc = this.formData.productEnglishDesc.substr(0,1000);
						}
					} else {
						this.submitLoading = false;
						return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderProductionTips'))
					}
				}
				
				this.formData.productChineseDesc = this.formData.productChineseDesc.substr(0, this.formData
					.productChineseDesc.length - 1);
				this.formData.productEnglishDesc = this.formData.productEnglishDesc.substr(0, this.formData
					.productEnglishDesc.length - 1);
				this.formData.declaredValue = this.form.declaredValue;
				this.formData.customsDeclarationFlag = this.form.customsDeclarationFlag;
				
				if(this.createType == '2' && this.form.dispatchType == ''){
					this.submitLoading = false;
					return this.$message.warning(this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryType'));
				}
				if(this.formData.declaredValue == 0){
					this.submitLoading = false;
					return this.$message.warning(this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.declaredValue'));
				}
				if(this.formData.skuCount == 0){
					this.submitLoading = false;
					return this.$message.warning(this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.skuCount'));
				}
				if(this.caseNum == 0){
					this.submitLoading = false;
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderInputClientPackageNumTips'));
				}
				
				
				//调用接口
				//上传订单信息

				if (this.form.destWhNo == 'custom') { //如果选择自定义海外仓，则仓库传空值，后台判断仓库和仓库地址只取一个值，所以两个一个要设置为空
					this.form.destWhNo = '';
				}
				
				this.isHandleSubmit = true;//验证显示
				if (this.isCaseSpecial) {
					if (this.caseDataCustom.length > 0) {
						// 特殊箱信息需要验证客户是否填写正确
						if(this.handleCheckCaseInfo(this.caseDataCustom)){
							this.caseDataCustom.forEach(item => {
								obj.clientLgPackageList.push(item.preNum + item.input + item.nextNum);
							})
						}else{
							this.submitLoading = false;
							return this.$message.warning(this.$t('chinaWhB2BOrderPage.caseInputTips'))
						}
					}
				}
				if (this.isCaseNormal) {
					if (this.caseData.length > 0) {
						this.caseData.forEach(item => {
							if (item.input.length !== 0) {
								obj.clientLgPackageList.push(item.input);
							}
						});
					}
				}
				this.orderCaseNum = obj.clientLgPackageList.length;
				
				obj = Object.assign(obj, this.formData);
				
				//第三个接口参数
				let wmsData = {
					customerBatchNo:this.form.orderNo,   //订单号
					productList:productList,
				}
				
				this.$refs["orderForm"].validate(valid => {
					if(valid){
						// this.submitLoading = true;
						this.$http.post("/toborder/update/dest", this.form).then(res => {
							if (res.code == 200) {
								that.$http.post("/toborder/update/lgPackageInfo", obj).then(resTwo => {
									if (resTwo.code === 200) {
										if(that.createType == '1'){
											that.$http.post("/toborder/wms/batch/add", wmsData).then(resThree => {
												if (resThree.code === 200) {
													that.submitLoading = false;
													that.orderPopup = false;
													that.getlist();
													this.showOrderDetail();
												} else {
													that.submitLoading = false;
													that.$message.error(resThree.msg);
												}
											})
										}else{
											that.submitLoading = false;
											that.orderPopup = false;
											that.getlist();
											this.showOrderDetail();
										}
									} else {
										that.submitLoading = false;
										that.$message.error(resTwo.msg);
									}
								})
							} else {
								that.submitLoading = false;
								that.$message.error(res.msg);
							}
						})
					}else{
						that.submitLoading = false;
					}
				})
				
			},
			
			//上传成功
			handlesuccess(files, fileList) {
				this.uploadloading = false;
				console.log(files);
			  if (files.code == 200) {
			    if (files.data.success) {
			      this.$message.success(this.$t('tipsInfo.uploadSuccess'));
						this.successData = files.data.productErrorInfoList;
						this.successSkuNum = files.data.skuAmount;
						this.successSkuLink = files.data.skuListLink;
						this.preFileList = [{
							name:this.$t('commonInfo.actionCheckFile'),
							url: files.data.skuListLink,
						}]
						//toSKU
						this.toSkuPreFileList = [{
							name:this.$t('commonInfo.actionCheckFile'),
							url: files.data.skuListLink,
						}];
						this.toSkuLink = files.data.skuListLink;
						this.toSkuProductList = files.data.productErrorInfoList;
						//上传成功也显示列表
						this.errorData = files.data.productErrorInfoList;
						this.dialogErrorVisible = true;
			    } else if (files.data.success == false) {
						this.successData = [];
						this.preFileList = [];
						this.successSkuLink = '';
						this.toSkuPreFileList = [];
						this.toSkuLink = '';
						this.toSkuProductList = [];
						
			      this.errorData = files.data.productErrorInfoList;
			      this.dialogErrorVisible = true;
						
						
			    }
			  } else if (files.code == 500) {
					this.successData = [];
					this.preFileList = [];
					this.successSkuNum = 0;
					
					this.toSkuPreFileList = [];
					this.toSkuLink = '';
					this.toSkuProductList = [];
			    this.$message.error(files.msg);
			  }
			  // console.log(files);
			
			  if (files.code == 401) {
			    this.$message.error(this.$t('tipsInfo.loginOutTips'));
			    window.sessionStorage.removeItem("token");
			    return this.$router.push({
			      path: "/login"
			    });
			  }
			  // console.log(files);
			},
			// 上传时触发
			beforeUpload(file) {
				let fileNameStr = file.name;
				if(fileNameStr.length > 30){
					return this.$message.warning(this.$t('tipsInfo.uploadFileNameLimitTips'));
				}
			  var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
			  const extension = testmsg === "xls";
			  const extension2 = testmsg === "xlsx";
			  if (!extension && !extension2) {
			    this.$message.warning(
			      this.$t('tipsInfo.uploadExcelTypeTips')
			    );
			  }
			  return extension || extension2;
			},
			// 上传失败
			handleError(err, file, fileList) {
				this.uploadloading = false;
			},//点击预览
			handlePreview(file) {
				window.open(file.url, "_blank");
			},
			// 上传时
			handleProgress(event, file, fileList) {
				this.uploadloading = true;
			  // this.$message.warning("Uploading, please hold on");
			},
		},
		filters: {
			// 格式化日期
			formatDate(time) {
				let dt = new Date(time);
				let y = dt.getFullYear();
				let M = (dt.getMonth() + 1 + "").padStart(2, 0);
				let d = (dt.getDate() + "").padStart(2, 0);
				let h = (dt.getHours() + "").padStart(2, 0);
				// let m = dt.getMinuted()
				let m = (dt.getMinutes() + "").padStart(2, 0);
				let s = (dt.getSeconds() + "").padStart(2, 0);
				return y + "-" + M + "-" + d + " " + h + ":" + m + ":" + s;
			}
		},
		created() {
			let customInfo = JSON.parse(window.sessionStorage.getItem("customInfo"));
			this.prefixAwbNo = customInfo.prefixAwbNo;
			this.frontOrafter = customInfo.frontOrafter;
			this.clientEnName = customInfo.clientEnName;
			this.isSpecial = customInfo.isSpecial;
			this.userName = customInfo.clientShortName;
			let token = window.sessionStorage.getItem("token");
			this.headers = {
				Authorization: token,
			}
			
			// 判断用户是否为赛时，是则创建批次显示carton整箱上架选项
			// this.showCarton = customInfo.clientId == 870?true:false;
			// 2022-4-1 zpy 开放整箱上架功能
			this.showCarton = true;
			
			this.getDict();
			this.getlist();
			this.getKindNameList();
		},
		mounted() {

		}
	};
</script>
<style scoped>
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.dialog_orderNo{
		width: 100%;
		height: 50px;
		line-height: 26px;
	}
	.dialog_orderNo label{
		float: left;
		margin-left: 40px;
		font-weight: bold;
		border-radius: 8px;
		font-size: 20px;
		padding: 5px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	.dialog_orderNo label:nth-child(2){
	  float: right;
	  color: #5981c9;
	  font-size: 18px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.app-container {
		padding: 10px;
	}

	.textareaNo {
		width: 250px;
	}

	.textareaNo .el-textarea__inner {
		max-height: 35px !important;
	}
	.productTip{
		font-size: 12px;
	  display: inline-block;
	  /* float: right;
	  height: 20px;
	  line-height: 20px;
	  width: 450px;
		margin-right: 6%; */
	  color: #b75b5b;
	}

	.pageBottom {
		padding: 20px;
	}
	.stepthrees {
		height: 100%;
		line-height: 45px;
	}
	
	.three>span {
		font-size: 16px;
	}
	
	.stepthree {
		padding: 20px;
	}
	
	.orderDetails {
		margin-bottom: 10px;
		font-size: 20px;
	}
	
	.el-divider--horizontal {
		margin: 20px 0 !important;
	}
	
	.stepthree div>span {
		display: inline-block;
		width: 100%;
		text-align: left;
	}
	/deep/ .expand .el-table__expand-column .cell {
	  display: none !important;
	}
	.expandDataRow{
	  border: 1px solid #a9c5e2;
	  margin: 10px auto;
	  border-radius: 5px;
	  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	}
	/deep/ .el-table__expand-icon > .el-icon{
	  font-size: 18px;
	}
	/deep/ .el-table__expand-icon{
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	/deep/ .el-table__expand-icon .el-icon-arrow-right:before{
	  content: '\e791';
	}
	/deep/ .el-table__expand-icon--expanded .el-icon-arrow-right:before{
	  content: '\e790';
	}
	/deep/ .el-card__header{
		padding: 5px 20px;
	}
	/deep/ .el-card__body{
		padding: 5px 20px;
	}
	/* 子订单号 */
	.dialog_subOrderNo{
	  width: 100%;
	  height: 40px;
	  line-height: 30px;
	}
	.dialog_subOrderNo label {
	  margin-left: 20px;
	  border-radius: 6px;
	  font-size: 16px;
	  padding: 5px 10px;
	}
	/deep/ .batchInfoItem{
		margin-left: 10px;
	}
	/deep/ .batchInfoItem .el-badge__content.is-fixed.is-dot{
		right: 0px;
		top: 6px;
	}
	/* 更多操作中分批信息样式 */
	/deep/ .batchInfoItem1 .el-badge__content.is-fixed.is-dot{
		right: 16px;
		top: 6px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		.dialog_orderNo {
			label{
				float: right;
				margin-right: 40px;
			}
		}
		::v-deep .el-timeline-item__node--normal{
			right: -15px;
		}
		.productRow{
			::v-deep .el-col{
				float: right;
			}
			::v-deep .el-form-item__label {
				float: right;
			}
			::v-deep .el-form-item__content {
				margin-left: 0px !important;
				margin-right: 180px;
			}
		}
		.stepthree div>span {
			text-align: right;
		}
		.fileUploadBtn {
			float: right;
			margin: 0 10px;
			max-width: 220px;
		}
	}
	.cartonCount{
		padding-left: 20px;
	  font-size: 16px;
	  margin-bottom: 10px;
	}
	.cartonCount label{
	  margin-right: 40px;
	}
</style>
